import { Router } from "@reach/router";
import * as React from "react";
import loadable from "@loadable/component";
import { PrivateComponentProps } from "../../components/common/privateComponent";
import PageLoading from "../../components/common/pageLoading";

const PrivateComponent = loadable<PrivateComponentProps & any>(
  () =>
    import(
      /* webpackChunkName:"private-component" */
      "../../components/common/privateComponent"
    ),
  {
    fallback: <PageLoading />,
  }
);
const LoanPage = loadable(
  () =>
    import(/* webpackChunkName:"loan-index" */ "../../templates/pages/loan"),
  {
    fallback: <PageLoading />,
  }
);
const NotFoundPage = loadable(
  () => import(/* webpackChunkName:"404" */ "../404"),
  {
    fallback: <PageLoading />,
  }
);

const App: React.FC = () => {
  return (
    <>
      <Router basepath="/loan">
        <PrivateComponent path="/" component={LoanPage} />

        <NotFoundPage default />
      </Router>
    </>
  );
};

export default App;
